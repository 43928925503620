import React from "react";
import { useLocation, matchPath } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
    Box,
    Drawer,
    Hidden,
    List,
    ListSubheader,
    makeStyles,

} from "@material-ui/core";
import NavItem from "./NavItem";

import { MdDashboard } from "react-icons/md"
import { FaUserCog } from "react-icons/fa"
import { BsDiamondHalf, BsDiamondFill } from "react-icons/bs"

import { BiBookContent } from "react-icons/bi"
import { BsArrowLeftRight } from "react-icons/bs"
import { RiFileUserFill } from "react-icons/ri"

const sections = [
    {
        items: [
            {
                title: "DashBoard",
                icon: MdDashboard,
                routeURL: "/dash",
            },
        ]
    },
    {
        items: [
            {
                title: "User Management",
                icon: FaUserCog,
                routeURL: "/usermanagement",
            },
        ]
    },
   

    {
        items: [
            {
                title: "User Bets",
                icon: RiFileUserFill,
                routeURL: "/mybet",
                items: [
                    {
                        title: " Active Bets",
                        icon: BsDiamondHalf,
                        routeURL: "/activebets",
                    },
                    {
                        title: " Settle Bets",
                        icon: BsDiamondFill,
                        routeURL: "/settle-bets",
                    },
                ]
            }],
    },
   


    {
        items: [
            {
                title: "Transactions",
                icon: BsArrowLeftRight,
                routeURL: "/transaction-history",
            },],
    },
   
    {
        items: [
            {
                title: "Content Management",
                icon: BiBookContent,
                routeURL: "/content",
            },],
    },
   

];

function renderNavItems({ items, pathname, depth = 0 }) {
    return (
        <List disablePadding>
            {items.reduce(
                (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
                []
            )}
        </List>
    );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
    const key = item.title + depth;

    if (item.items) {
        const open = matchPath(pathname, {
            path: item.routeURL,
            exact: true,
        });

        acc.push(
            <NavItem
                depth={depth}
                icon={item.icon}
                info={item.info}
                key={key}
                open={Boolean(open)}
                title={item.title}
            >
                {renderNavItems({
                    depth: depth + 1,
                    pathname,
                    items: item.items,
                })}
            </NavItem>
        );
    } else {
        acc.push(
            <NavItem
                depth={depth}
                routeURL={item.routeURL}
                icon={item.icon}
                info={item.info}
                key={key}
                title={item.title}
            />
        );
    }

    return acc;
}

const useStyles = makeStyles((theme) => ({
    mobileDrawer: {
        width: 256,
        background: "#222222",
        backdropFilter: "blur(44px)",
    },
    desktopDrawer: {
        width: "17%",
        top: "78px",
        height: "100%",
        marginLeft:'1.5rem',
        background: "#222222",
        backdropFilter: "blur(44px)",
        boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    },
    avatar: {
        cursor: "pointer",
        width: 64,
        height: 64,
    },
    socialIcon: {
        cursor: "pointer",
        marginRight: 5,
    },
    logoicon: {
        display: "flex",
        marginTop: "16px",
        alignItems: "center",
        marginLeft: "30px",
    },

    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    logoutButton: {
        textAlign: "left",
        display: "flex",
        justifyContent: "flex-start",
        color: "#fff",

        "&:hover": {
            color: "#fff",
            background: "rgb(90 134 255) !important",
            borderRadius: "none !important",
        },
    },
    itemLeaf: {
        display: "flex",
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: "2px",
    },
    buttonLeaf: {
        color: "#fff",
        padding: "11px 8px",
        justifyContent: "flex-start",
        textTransform: "none",
        letterSpacing: 0,
     
        borderRadius: 0,
        fontSize: "13px",
        borderLeft: "3px solid transparent",
        "&:hover": {
            background: "rgb(90 134 255)",
            color: "#fff",
        },

        "&.depth-0": {
            "& $title": {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
    },
    active: {
        borderLeft: "3px solid #fff",
        

        fontWeight: theme.typography.fontWeightRegular,
        background: "",
        "& $title": {
            fontWeight: theme.typography.fontWeightMedium,
            
        },
        "& $icon": {
            color: "00e0b0",
           
        },
    },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
    const classes = useStyles();
    const location = useLocation();


  
    const content = (
        <Box
            height="100%"

            display="flex"
            flexDirection="column"
            className="example"
        >
            <PerfectScrollbar options={{ suppressScrollX: true }}>
               


                <Box pt={1}>
                    {sections.map((section, i) => (
                        <List
                            key={`menu${i}`}
                            subheader={
                                <ListSubheader disableGutters disableSticky>
                                    {section.subheader}
                                </ListSubheader>
                            }
                        >
                            {renderNavItems({
                                items: section.items,
                                pathname: location.pathname,
                            })}
                        </List>
                    ))}
                </Box>

            </PerfectScrollbar>


        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.mobileDrawer }}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.desktopDrawer }}
                    open
                    variant="persistent"
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
};

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool,
};

export default NavBar;