import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "55rem",
    backgroundSize: "cover",
    width: '100%',
    backgroundColor: "#1A1A1A",
    backgroundImage: "url(images/layoutbackground.svg)",
  },
  MainContainer: {
    paddingTop: "15%",
    "@media (max-width:600px)": {
      paddingTop: "30%"
    },
    "@media (max-width:500px)": {
      paddingTop: "35%"
    },
  },
  content: {
    backgroundColor: "none",
  },
  content1: {
    borderRadius: "8px",
    background: "#F4F8FE",
    height: "auto",
    color: "#000",
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="xs" className={classes.MainContainer}>
        <Box className={classes.content1}>{children}</Box>
      </Container>
    </div>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
