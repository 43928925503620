import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import LoginLayout from "./layouts/LoginLayout/index";
import DashboardLayout from "./layouts/DashboardLayout1";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("./views/Auth/Login")),
  },
  {
    exact: true,
    path: "/forgot",
    layout: LoginLayout,

    component: lazy(() => import("./views/Auth/forgotpassword/index")),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,

    component: lazy(() => import("./views/Auth/resetpassword/index")),
  },
  {
    exact: true,
    path: "/otp-verify",
    layout: LoginLayout,

    component: lazy(() => import("./views/Auth/otpverify/index.js")),
  },
  {
    exact: true,
    path: "/dash",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("./views/pages/Dashboard/index")),
  },
  {
    exact: true,
    path: "/logout",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("./views/pages/Logout/index")),
  },
  {
    exact: true,
    path: "/usermanagement",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("./views/pages/UserManagement/index.js")),
  },
  {
    exact: true,
    path: "/adduser",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("./views/pages/UserManagement/AddUser.js")),
  },
  {
    exact: true,
    path: "/registereduser",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("./views/pages/Dashboard/Registeredusers.js")),
  },
  {
    exact: true,
    path: "/totalwinners",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("./views/pages/Dashboard/TotalWinners.js")),
  },
  {
    exact: true,
    path: "/totallossers",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("./views/pages/Dashboard/TotalLossers.js")),
  },
  {
    exact: true,
    path: "/totalbets",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("./views/pages/Dashboard/TotalBets.js")),
  },
  {
    exact: true,
    path: "/totalteams",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("./views/pages/Dashboard/TotalTeam.js")),
  },
  {
    exact: true,
    path: "/activebets",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("./views/pages/MyBet/ActiveBets.js")),
  },
  {
    exact: true,
    path: "/settle-bets",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("./views/pages/MyBet/SettleBets.js")),
  },

  {
    exact: true,
    path: "/transaction-history",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("./views/pages/Dashboard/Transactionhistory.js")),
  },
  {
    exact: true,
    path: "/user-transaction",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("./views/pages/Dashboard/UserTransaction.js")),
  },
  {
    exact: true,
    path: "/total-view",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("./views/pages/Dashboard/TotalUserView.js")),
  },

  {
    exact: true,
    path: "/user-history",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("./views/pages/UserManagement/UserHistory.js")),
  },
  {
    exact: true,
    path: "/user-detail",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("./views/pages/UserManagement/UserDetail.js")),
  },
  {
    exact: true,
    path: "/content",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Content Management/index.js")),
  },
  {
    exact: true,
    path: "/privacy",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("./views/pages/Privacy Policy/index.js")),
  },
  {
    exact: true,
    path: "/faq",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("./views/pages/Dashboard/Faqs/index.js")),
  },
  {
    exact: true,
    path: "/addfaq",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("./views/pages/Dashboard/Faqs/EditFaq.js")),
  },
  {
    exact: true,
    path: "/edit-privacy",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("./views/pages/Privacy Policy/EditPrivacy.js")),
  },
  {
    exact: true,
    path: "/edit-about",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("./views/pages/Dashboard/About Us/EditAbout.js")),
  },
  {
    exact: true,
    path: "/about",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("./views/pages/Dashboard/About Us/index.js")),
  },
  {
    exact: true,
    path: "/profile",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("./views/pages/MyProfile/Profile.js")),
  },
  {
    exact: true,
    path: "/terms",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("./views/pages/Terms and Conditions/index.js")),
  },
  {
    exact: true,
    path: "/edit",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("./views/pages/MyProfile/EditProfile.js")),
  },
  {
    exact: true,
    path: "/change-password",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("./views/pages/MyProfile/ChangePassword.js")),
  },
  {
    exact: true,
    path: "/settings",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("./views/pages/Settings")),
  },
  {
    exact: true,
    path: "/faq-view1",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("./views/pages/Dashboard/Faqs/faqview.js")),
  },

  {
    exact: true,
    path: "/mybet",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("./views/pages/MyBet/index.js")),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("./views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
