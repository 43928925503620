const initialState = {
    data:
    {
        totalUser: 0,
        totalActiveBets: 0,
        totalSettleBets: 0,
        totalBets: 0,
        totalTransaction: 0,
        totalWinner: 0,
        totalLosser: 0,
        winSumAmount: 0,
        lossSumAmount: 0
    }
};

const dashboardCountReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_DASHBOARD_COUNT": return action;
        default: return state;
    }
}

export default dashboardCountReducer;