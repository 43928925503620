import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Toolbar,
  makeStyles,
  Box,
  Button,
  IconButton
} from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import RightSideDrawer from "./RightSideDrawer";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

const useStyles = makeStyles((theme) => ({
  root: {

  },
  desktopDrawer1: {
    position: "absolute",
    right: 73,
    top: 0,
    width: "240px !important",
    padding: "12px",
    color: "#000",

  },
  toolbar: {
    padding: "0 10px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "0px 0px 20px rgb(0 0 0 / 50%)",
    position: "absolute",
    top: -2,
    background: "#222222",
    "@media (max-width: 1279px)": {
      width: "100%",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),

    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: "0.8",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    border: "0.5px solid #DBD8FC",
    borderRadius: "10px",
    opacity: "0.9",
    boxShadow:
      "0px 100px 80px rgba(41, 72, 152, 0.05), 0px 64.8148px 46.8519px rgba(41, 72, 152, 0.037963), 0px 38.5185px 25.4815px rgba(41, 72, 152, 0.0303704), 0px 20px 13px rgba(41, 72, 152, 0.025), 0px 8.14815px 6.51852px rgba(41, 72, 152, 0.0196296), 0px 1.85185px 3.14815px rgba(41, 72, 152, 0.012037)",

    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "61ch",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },

  FiterBox: {
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },
  icon: {
    fontSize: "25px",
    padding: "0px 20px",
    cursor: "pointer",
  },
  input: {
    borderRadius: "7px",
    background: "#fff",
  },
  btn: {
    marginLeft: '-50%',

  },
  connect: {
    background: 'linear-gradient(113.53deg, #2AC6F1 2.39%, #4681EF 54.27%, #5D3EFF 99.22%)',
    borderRadius: '50px',
    width: '80%'

  }

}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();

  const logout = () => {
    localStorage.removeItem("token");
    history.push("/");
  }

  return (
    <AppBar
      elevation={0}
      className={clsx(classes.root, className)}
      color="inherit"
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <img src="images/dexwinlog.png" style={{ marginLeft: "2rem" }} alt="img" />

        <Box style={{ marginLeft: "4rem" }}>

        </Box>
        <Box
          style={{ display: "flex", alignItems: "center", marginRight: "7rem" }}
        >
          <IconButton aria-label="logout" className={classes.margin} onClick={() => logout()}>
            <PowerSettingsNewIcon style={{ color: "white" }} />
          </IconButton>
          {/* <Box className={classes.FiterBox}>
            <Box style={{ display: "flex", alignItems: "center" }}>

            </Box>
          </Box>
          &nbsp;&nbsp;
          <RightSideDrawer /> */}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => { },
};

export default TopBar;
