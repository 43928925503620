import React, { useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
    Button,
    Collapse,
    ListItem,
    makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const useStyles = makeStyles((theme) => ({
    item: {
        display: "block",
        paddingTop: 0,
        paddingBottom: 0,
    },
    itemLeaf: {
        display: "flex",
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: "2px",
    },
    button: {
        color: "#707070",
        padding: "10px 0",
        justifyContent: "flex-start",
        textTransform: "none",
        letterSpacing: 0,
        width: "100%",
    },
    buttonLeaf: {
        color: "#787878",
        padding: "11px 8px",
        justifyContent: "flex-start",
        textTransform: "none",
        letterSpacing: 0,
        width: "100%",
  
        borderRadius: 0,
        fontSize: "16px",
        borderLeft: "3px solid transparent",
        "&:hover": {
           
            color: "#fff",
        },
        "&.depth-0": {
            "& $title": {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
    },
    icon: {
        display: "flex",
        alignItems: "center",
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(1),
    },
    title: {
        marginRight: "auto",
    },
    active: {
        borderLeft: "3px solid #8f5fe8",
  
        fontWeight: theme.typography.fontWeightRegular,
        background: "",
        color:'#8f5fe8',
        "& $title": {
            fontWeight: theme.typography.fontWeightMedium,
        },
        "& $icon": {
            color: "00e0b0",
        },
    },
}));

const NavItem = ({
    children,
    className,
    depth,
    routeURL,
    icon: Icon,
    info: Info,
    open: openProp,
    title,
    ...rest
}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(openProp);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    let paddingLeft = 8;

    if (depth > 0) {
        paddingLeft = 32 + 8 * depth;
    }

    const style = { paddingLeft };

    if (children) {
        return (
            <ListItem
                className={clsx(classes.item, className)}
                disableGutters
                key={title}
                {...rest}
            >
                <Button
                    activeClassName={classes.active}
                    className={clsx(classes.buttonLeaf, `depth-${depth}`)}
                    onClick={handleToggle}
                    style={{ paddingLeft: "10px" }}
                >
                    {Icon && <Icon className={classes.icon} size="20" />}
                    <span className={classes.title}>{title}</span>
                    {open ? <ExpandLessIcon style={{ paddingRight: "10px" }} /> : <ExpandMoreIcon style={{ paddingRight: "10px" }} />}
                </Button>
                <Collapse in={open} style={{ paddingLeft: "10px" }}>
                    {children}
                </Collapse>
            </ListItem>
        );
    }

    return (
        <>
            <ListItem
                className={clsx(classes.itemLeaf, className)}
                disableGutters
                key={title}
                {...rest}
            >
                <Button
                    activeClassName={classes.active}
                    className={clsx(classes.buttonLeaf, `depth-${depth}`)}
                    component={RouterLink}
                    exact
                    style={style}
                    to={routeURL}
                >
                    {Icon && <Icon className={classes.icon} size="20" />}
                    <span className={classes.title}>{title}</span>
                    {Info && <Info />}
                </Button>
            </ListItem>
        </>
    );
};

NavItem.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    depth: PropTypes.number.isRequired,
    routeURL: PropTypes.string,
    icon: PropTypes.elementType,
    info: PropTypes.elementType,
    open: PropTypes.bool,
    title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
    open: false,
};

export default NavItem;