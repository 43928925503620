import React, { createContext, useState } from "react";


export const AuthContext = createContext();

function checkLogin() {

  const accessToken = localStorage.getItem("createurAccessToken");

  return accessToken ? true : false;
}

export default function AuthProvider(props) {


  const [isLogin, setIsLogin] = useState(checkLogin());


  let data = {
    userLoggedIn: isLogin,
    userLogIn: (type) => {
      setIsLogin(type);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
