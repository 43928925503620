import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box, Grid, makeStyles } from "@material-ui/core";

import { useHistory } from "react-router-dom";

  const useStyles = makeStyles((theme) => ({
    ContentLogout: {
      textAlign: "center",
      color: "black",
      fontFamily: "Inter",
    },
    Title: {
      textAlign: "center",
      fontWeight: "300",
      fontFamily: "Inter",
    },
    ButtonItem:{
      textAlign: "center"
    },
    ClosedButton: {
      background: "linear-gradient(105.19deg,#8de6d1 2.66%,#2fb3fc 81.96%),linear-gradient(105.19deg,#8de6d1 2.66%,#2fb3fc 81.96%)!important",

      borderRadius: "8px",
      fontFamily: "Inter",
      height: "79%",
      width:"79%",
      fontSize: "18px !important",
      border: " 3px solid #70D5FB",
      "&:hover": {
        background: "#fff",
        color: "black",
        fontFamily: "Inter",
        border: " 3px solid #70D5FB",
      },
    },    
  }));

  export default function Logout({ open, handleClose }) {
    const classes = useStyles();
    const history = useHistory();

    return (
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box pt={1} pb={2} pl={4} pr={4}>
            <DialogTitle id="alert-dialog-title" className={classes.Title}>
              {"Logout"}
            </DialogTitle>

            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                className={classes.ContentLogout}
              >
                Are you sure you want to LOGOUT?
              </DialogContentText>
            </DialogContent>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={6} className={classes.ButtonItem}>
                <Button
                  className={classes.ClosedButton}
                  to="/login"
                  onClick={() => {
                    handleClose();
                    localStorage.removeItem("createurAccessToken")
                    history.push("/");
                  }}
                >
                  Yes
                </Button>
              </Grid>{" "}
              <Grid item xs={6} className={classes.ButtonItem}>
                <Button
                  className={classes.ClosedButton}
                  onClick={handleClose}
                  autoFocus
                >
                  No
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Dialog>
      </div>
    );
  }
